import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap';
import "./style.scss"
import Logo from "../assets/images/Logo (1).png"
import Image1 from "../assets/images/Image_01.png"
import Image2 from "../assets/images/Image_02.png"
import Image3 from "../assets/images/Image_03.png"
// import { Drawer } from 'antd';
// import { IoMdMenu } from "react-icons/io";
import moment from 'moment-timezone';



const Header = () => {
  const [times, setTimes] = useState({
    uaeTime: moment().tz("Asia/Dubai"),
    newYorkTime: moment().tz("America/New_York"),
    londonTime: moment().tz("Europe/London")
  });
  useEffect(() => {
    const timer = setInterval(() => {
      setTimes({
        uaeTime: moment().tz("Asia/Dubai"),
        newYorkTime: moment().tz("America/New_York"),
        londonTime: moment().tz("Europe/London")
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
        {/* <Container> */}
        <Row>
            <div className='header'>
            <Col lg="4" md="4 ">
            <div className='header-options'>
              <div className='header-conection'>
                 <div className='header-option'>LIVE RATES</div>
                 <div  className='header-option'>PRODUCTS</div>
                 <div  className='header-option'>BOOKING DESK</div>
                 <div  className='header-option'>CONTACTUS</div>
                 </div>
            </div>
            </Col>
            <Col lg="5" md="5">
            <div className='header-logo'><img src={Logo} alt="" className='img-responcive'width={450} /></div>
            </Col>
           
            <Col md="3" lg="3">
            <div className='contry'>
                <div className='contrys-div'><img src={Image1} alt="" width={50} />
               </div>
                <div className='contrys-div'><img src={Image2} alt="" width={50} /></div>
                <div className='contrys-div'><img src={Image3} alt="" width={50}/></div>
                   </div>
                   <div className='new-time'> 
                    <div>   <p>{times.uaeTime.format(' ddd h:mm:ss ')}</p></div>
                    <div>  <p> {times.newYorkTime.format('ddd h:mm:ss ')}</p></div>
                    <div>   <p>{times.londonTime.format('ddd h:mm:ss ')}</p></div>
                   </div>
            </Col>
            {/* <Col md="1" lg='1'>
            <>
            <div className='drower-menu'  onClick={showDrawer}>
            <IoMdMenu color='gold' size={50}/>
            </div>
      <Drawer  title="custom Drawer" onClose={onClose} open={open}
         placement="right" 
      width={300}
      height={200}
        headerStyle={{ backgroundColor: 'gold', color: '#02410c' }} 
        bodyStyle={{backgroundColor:'#02410c',color:'gold'}}
      >
      <div className='drower-options'>
      <p className='under-line'>LIVE RATES</p>
        <p  className='under-line'>PRODUCTS</p>
        <p  className='under-line'>BOOKING DESK </p>
        <p  className='under-line'>CONTACTUS</p>
      </div>
       
      </Drawer>
    </>
            </Col> */}
            </div>
        </Row>
        {/* </Container> */}
    </div>
  )
}

export default Header