import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import "./App.css";
import Footer from "./footer/pagr";
import Header from "./header/page";
import Home from "./home/page";
import { rate, storeChart } from "./redux/slices/liverate.slice";

function App() {
  const dispatch = useDispatch();

  // const [rate ,setRate]= useState()
  // useEffect(() => {
  //   const socket = io("wss://digigold-server.semikolen.com/liverate/", {
  //     transports: ["websocket"],
  //   });
  //   socket.on("data", (newData) => {
  //     console.log("====shibu mon=====",newData)
  //     if (newData?.length) {
  //       setRate(newData)
  //     }
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    const now = new Date();
    const weekendDays = [0, 6];
    if (weekendDays.includes(now.getDay())) {
      fetchFallbackData();
    }
  }, []);
  const fetchFallbackData = async () => {
    try {
      const response= await axios.get('https://digigold-server.semikolen.com/liverate/closingRate');
      if (response?.data?.status) {
        dispatch(rate(response?.data?.data?.closingRate));
        dispatch(storeChart(response?.data?.data?.closingRate));
      } else {
        console.error("Failed to fetch fallback live rates");
      }
    } catch (error) {
      console.error("Error fetching fallback live rates", error);
    }
  };

  useEffect(() => {
    const socket = io("wss://digigold-server.semikolen.com/liverate", {
      transports: ["websocket"],
    });
    socket.on("data", (newData) => {
      
      if (newData?.length) {
        dispatch(rate(newData));
        dispatch(storeChart(newData));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
