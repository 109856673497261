import React from 'react'
import "./style.scss"
import { Row,Col } from 'react-bootstrap'
const Footer = () => {
  return (
 
    <Row className='g-0'>
      <Col lg="12" md="12">
    <div className='footer-div'>
      <div className='footer-box'>
          <p>COPYRIGHTS © LUCKY STAR</p>
          <p>POWERED BY : SEMIKOLEN IT SOLUTIONS</p>
         </div>
    </div>
    </Col>
    </Row>
   
  )
}

export default Footer